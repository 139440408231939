import { Controller } from  "@hotwired/stimulus"
export default class extends Controller {
  static targets = ["datePicker", "productsModal", "modalBody", "tableProducts", "search",
                    "rowProducts", "addElement", "productCheck", "amount", "totalUsd",
                    "exchangeRate"];

  connect(){
    this.initializeDatePicker();
  }

  initializeDatePicker(){
    let date = this.datePickerTarget.value
    $(this.datePickerTarget).datetimepicker({
      viewMode: 'years',
      format: 'MM/YYYY',
      useCurrent: false,
    });
  }

  showModal(event){
    $(this.productsModalTarget).modal('show')
  }

  fetchProducts(e){
    let rows = this.rowProductsTarget
    $.ajax({
      url: '/sale_forecasts/products?query=' + encodeURIComponent(this.searchTarget.value),
      method: 'GET',
      success: function(response) {
        let productElement = ''
        response.products.forEach((element) => {
          productElement += `
            <tr>
              <td>${element.part_number}</td>
              <td>${element.name}</td>
              <td><input type='number' name='amount' class='form-control modalAmount' data-action='keyup->sale-forecasts--form#getAmount'></td>
              <td><select class="form-control select optional modalCurrency" name="currency">
                <option selected="selected" value="usd">USD</option>
                <option value="mxn">MXN</option></select>
              </td>
              <td><input type="checkbox" data-action='click->sale-forecasts--form#checkProduct' data-sale-forecasts--form-target='productCheck' disabled='true' data-product='${JSON.stringify(element)}' class='checkProduct'></td>
            </tr>
          `;
        });
        rows.innerHTML = productElement;
      },
      error: function(){
        console.log('error')
      }
    })
  }

  checkProduct(e){
    this.buildProductItem(e.currentTarget)
    $(this.addElementTarget).trigger('click')

    $(e.currentTarget).parent().parent().fadeOut(500, function() {
      $(this).remove();
    });
  }

  buildProductItem(element){
    let values = element.getAttribute('data-product')
    let data = JSON.parse(values)
    let row = $(element).parent().parent()
    let amount = row.find('.modalAmount').val()
    let currency = row.find('.modalCurrency').val()
    let _this = this
    $(document).on('cocoon:after-insert', function(e, item){
      $(item).find('.productPartNumber').text(data.part_number.slice(0, 34))
      $(item).find('.productPartNumber').attr('title', data.part_number)
      $(item).find('.productName').text(data.name.slice(0, 26))
      $(item).find('.productName').attr('title', data.name)
      $(item).find('.inputPartNumber').val(data.part_number)
      $(item).find('.inputProductName').val(data.name)
      $(item).find('.inputProductId').val(data.id)
      $(item).find('.inputAmount').val(amount)
      $(item).find('.inputCurrency').val(currency)
      _this.setTotal()
    })
  }

  getAmount(e){
    let row = $(e.currentTarget).parent().parent()
    let amount = row.find('.modalAmount').val()
    let check = row.find('.checkProduct')
    if(amount == ''){
      check.prop('disabled', true)
    }else{
      check.prop('disabled', false)
    }
  }

  sumAll(){
    let total = 0;
    let exchangeRate = this.exchangeRateTarget.value
    this.amountTargets.forEach((amountTarget) => {
      let amount = parseFloat(amountTarget.value);
      let currency = $(amountTarget).closest('.rowProduct').find('.inputCurrency').val();
      if (!isNaN(amount)) {
        amount = (currency == 'mxn') ? (amount / parseFloat(exchangeRate)) : amount
        total += amount;
      }
    });
    console.log("Total:", total);
    return  total.toFixed(3);
  }

  setTotal(){
    this.totalUsdTarget.value = this.sumAll()
  }

  removeItem(){
    setTimeout(() => this.setTotal(), 300)
  }

  changeCurrency(){
    this.setTotal()
  }
}